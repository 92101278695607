import { logDatapoint as logDatapointCore } from 'owa-analytics-core/lib/loggingFunctions/logDatapoint';
import type { LogDatapointParams } from 'owa-analytics-types';
import { getAnalyticsAddon } from 'owa-analytics-addons';
import { getThreadName } from 'owa-thread-config';
import { queueOrRun } from 'owa-analytics-utils';
import { logToTracingPanel } from '../../utils/logToTracingPanel';
import { getAnalyticsFlightsAndAppSettings } from 'owa-analytics-core/lib/settings/getAnalyticsFlightsAndAppSettings';

export function logDatapoint(...[datapoint, overrideEventType]: LogDatapointParams) {
    logToTracingPanel(datapoint);

    queueOrRun<LogDatapointParams>(
        'logDatapoint',
        logDatapointFunction,
        datapoint,
        overrideEventType
    );
}

export function logDatapointFunction(...[datapoint, overrideEventType]: LogDatapointParams) {
    if (getThreadName() === 'MAIN_THREAD') {
        getAnalyticsAddon('LogInWorkerOrMainThread')?.executeWhenReady(
            logDatapointCore,
            'logDatapoint',
            datapoint,
            overrideEventType
        );
    } else {
        logDatapointCore(datapoint, overrideEventType);
    }

    if (getAnalyticsFlightsAndAppSettings().devToolsEnabled) {
        getAnalyticsAddon('LogUnifiedTelemetryDiagnostics')?.executeWhenReady(datapoint);
    }
}
