import { registerCreateServiceResponseCallback as registerOwsCreateServiceResponseCallback } from 'owa-service/lib/fetchWithRetry';
import { registerCreateServiceResponseCallback as registerOwsPrimeCreateServiceResponseCallback } from 'owa-ows-gateway/lib/registerCreateServiceResponseCallback';
import { isRunningOnWorker } from 'owa-config';
import { trace } from 'owa-trace';
import { getAnalyticsAddon } from 'owa-analytics-addons';

export function registerOwsCallbacks() {
    trace.info(` Registering OWS Callbacks. Is a Web Worker? ${isRunningOnWorker()}`, 'analytics');
    const addon = getAnalyticsAddon('CaptureServiceActionOptics');
    const captureServiceActionOpticsCallback = addon?.executeWhenReady;

    registerOwsCreateServiceResponseCallback(captureServiceActionOpticsCallback);
    registerOwsPrimeCreateServiceResponseCallback(captureServiceActionOpticsCallback);
}
