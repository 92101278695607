import { isResourceTimingSupported } from 'owa-analytics-start';
import type { CalculatedResourceTimings } from 'owa-analytics-types';
import calculateTiming from './calculateTiming';
import { getAnalyticsAddon } from 'owa-analytics-addons';
import { increaseResources } from './resourceCount';

type ResourceTimingCallback = (timings: CalculatedResourceTimings | undefined) => void;
const sequenceNumbersToCapture: {
    [sequenceNumber: string]: ResourceTimingCallback;
} = {};
let cachedResourceTimings: {
    [sequenceNumber: string]: PerformanceResourceTiming;
} = {};

let timeoutId: number | undefined;
export default function getResourceTimingForUrl(
    url: string
): Promise<CalculatedResourceTimings | undefined> {
    return new Promise(resolve => {
        const sequenceNumber = extractSequenceNumber(url);
        if (!isResourceTimingSupported() || sequenceNumber == -1) {
            return resolve(undefined);
        }

        sequenceNumbersToCapture[sequenceNumber] = addToExistingFunction(
            sequenceNumbersToCapture[sequenceNumber],
            resolve
        );

        // we will try to resolve it now
        resolveUrls();
        self.clearTimeout(timeoutId);
        timeoutId = self.setTimeout(() => {
            resolveUrls();

            // if we can't capture the url after the timeout, we will resolve the callbacks with undefined
            for (const sequenceNumberToCapture of Object.keys(sequenceNumbersToCapture)) {
                resolveSequenceNumber(sequenceNumberToCapture);
            }
            cachedResourceTimings = {};
        }, 2000);
    });
}

function addToExistingFunction(
    existingFunction: ResourceTimingCallback,
    newFunction: ResourceTimingCallback
): ResourceTimingCallback {
    if (existingFunction) {
        return function (timing: CalculatedResourceTimings | undefined) {
            existingFunction(timing);
            newFunction(timing);
        };
    }
    return newFunction;
}

function resolveUrls() {
    const timings = self.performance.getEntriesByType('resource') as PerformanceResourceTiming[];
    increaseResources(timings.length);
    cachedResourceTimings = timings.reduce((agg, timing) => {
        const sn = extractSequenceNumber(timing.name);
        if (sn > -1) {
            agg[sn] = timing;
        }
        return agg;
    }, cachedResourceTimings || {});

    getAnalyticsAddon('CaptureAssetsOptics')?.executeWhenReady(
        timings.map(t => calculateTiming(t))
    );

    if (self.performance.clearResourceTimings) {
        self.performance.clearResourceTimings();
    } else if (self.performance.webkitClearResourceTimings) {
        self.performance.webkitClearResourceTimings();
    }
    for (const sequenceNumber of Object.keys(sequenceNumbersToCapture)) {
        const timing = cachedResourceTimings[sequenceNumber];
        if (timing) {
            // we found the timing so lets call the callback
            resolveSequenceNumber(sequenceNumber, timing);
        }
    }
}

function resolveSequenceNumber(sn: string, timing?: PerformanceResourceTiming) {
    sequenceNumbersToCapture[sn](calculateTiming(timing));
    delete sequenceNumbersToCapture[sn];
    delete cachedResourceTimings[sn];
}

// This will try to extract the sequence number from a url such as the one below
// https://outlook-sdf.office.com/owa/service.svc?action=GetConversationItems&n=25&app=Mail
const extractSequenceNumberRegex = /[?&]n=([\d]+)(&|$)/;
function extractSequenceNumber(url: string): number {
    const match = extractSequenceNumberRegex.exec(url);
    if (match) {
        return parseInt(match[1]);
    }
    return -1;
}
