import { logPerformanceDatapoint as logPerformanceDatapointCore } from 'owa-analytics-core';
import type { LogPerformanceDatapointParams } from 'owa-analytics-types';
import { getAnalyticsAddon } from 'owa-analytics-addons';
import { getThreadName } from 'owa-thread-config';
import { queueOrRun } from 'owa-analytics-utils';
import { logToTracingPanel } from '../../utils/logToTracingPanel';

export function logPerformanceDatapoint(
    ...[datapoint, status, errorType]: LogPerformanceDatapointParams
) {
    logToTracingPanel(datapoint, status);

    queueOrRun(
        'logPerformanceDatapoint',
        logPerformanceDatapointFunction,
        datapoint,
        status,
        errorType
    );
}

export function logPerformanceDatapointFunction(
    ...[datapoint, status, errorType]: LogPerformanceDatapointParams
) {
    if (getThreadName() === 'MAIN_THREAD') {
        getAnalyticsAddon('LogInWorkerOrMainThread')?.executeWhenReady(
            logPerformanceDatapointCore,
            'logPerformanceDatapoint',
            datapoint,
            status,
            errorType
        );
    } else {
        logPerformanceDatapointCore(datapoint, status, errorType);
    }
}
