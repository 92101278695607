import { AriaDatapoint } from './AriaDatapoint';

export default class ProfilerDatapoint extends AriaDatapoint {
    constructor(scenario: string, duration: number, trace: string) {
        super('Profile');
        this.addData('Scenario', scenario);
        this.addData('Trace', trace);
        this.addData('E2ETimeElapsed', duration);
    }
}
