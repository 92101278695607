import UsageDatapoint from '../../datapoints/UsageDatapoint';
import { logDatapoint } from './logDatapoint';
import type { CustomData, DatapointOptions, LogUsageEventTypes } from 'owa-analytics-types';

export function logUsage(
    eventName: string,
    customData?: CustomData,
    options?: DatapointOptions,
    overrideEventType?: LogUsageEventTypes
) {
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a string literals as the first argument of the constructor. */
    const datapoint = new UsageDatapoint(eventName, customData, options);
    logDatapoint(datapoint, overrideEventType);
}
